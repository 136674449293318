import React, { useState, useEffect, useContext } from 'react';
import { drupalRegister } from '../../util/auth/register';
import Loading from '../../assets/media/Rhombus.gif';
import { navigate } from 'gatsby';
import { handleLogin } from '../../util/auth/drupal';
import { UserContext } from '../../context/user/context';

export default function BecomeAMember({
  onLogin,
  first_name = '',
  last_name = '',
  email = '',
}) {
  const [firstName, setFirstNameField] = useState(first_name);
  const [lastName, setLastNameField] = useState(last_name);
  const [mail, setEmailField] = useState(email);
  const [pass, setPasswordField] = useState('');
  const [confirmPass, setConfirmPasswordField] = useState('');
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(null);

  const { userLogin } = useContext(UserContext);

  const handleFormSubmission = (e) => {
    e.preventDefault();
    if (!submit) {
      setSubmit(true);
      setLoading(true);
    }
  };

  const validateFormFields = () => {
    if (!submit) return true;

    let errors = {};
    if (!pass || !confirmPass) {
      // password too weak
      errors.confirmpass = 'You must set a password.';
    }
    if (pass !== confirmPass) {
      // password too weak
      errors.confirmpass = "Your passwords don't match.";
    }
    if (firstName.length === 0) {
      // first name not long enough
      errors.fname = 'You must provide a first name';
    }
    if (lastName.length === 0) {
      // last name not long enough
      errors.lname = 'You must provide a last name';
    }
    if (mail.length === 0) {
      // email not long enough
      errors.mail = 'You must provide an email';
    }
    if (!agreement) {
      errors.agreement = 'You must agree to the terms and conditions.';
    }
    if (Object.keys(errors).length) {
      setError(errors);
      setSubmit(false);
      setLoading(false);
      return false;
    }
    return true;
  };

  const logUserIn = async () => {
    try {
      setLoading(true);
      const res = await handleLogin(mail, pass);

      if (res && res.data) {
        userLogin(res);
        navigate('/user');
      }

      if (res.message) {
        setError(extractHumanFriendlyError(res.message));
      }

      setLoading(false);
    } catch (err) {
      setError(extractHumanFriendlyError(err.message));
      setLoading(false);
    }
  };

  // register on submit
  useEffect(() => {
    async function fetchRegister() {
      // invoke early
      setSubmit(false);

      // registration request
      const res = await drupalRegister({
        mail,
        pass,
        fname: firstName,
        lname: lastName,
      });

      // there's an error, display it
      if (res.error) {
        setError(extractHumanFriendlyError(res.msg));
        setSuccess(null);
      }
      // no error, do stuff
      else {
        setSuccess(true);
        setError({});
        await logUserIn();
        //onLogin({success: true});
      }

      setLoading(false);
    }

    // validate form fields first
    if (validateFormFields()) {
      if (submit) {
        // if submit, fetch
        fetchRegister();
      }
    }
  }, [submit]);

  // take the response from drupal and make it more...
  // useful.
  const extractHumanFriendlyError = (response) => {
    let friendlyErrors = {};
    let errors = response
      .replace('Unprocessable Entity: validation failed.', '')
      .split('\n');
    for (const i in errors) {
      let error = errors[i];

      const uname_error = 'name:';
      const mail_error = 'mail:';
      const pass_error = 'pass:';
      const nopass_error = 'No password provided.';
      const fname_error = 'field_first_name:';
      const lname_error = 'field_last_name:';
      const confirmation_error = 'confirmation_notice: ';

      switch (true) {
        case error.includes(uname_error):
          friendlyErrors.name = error.replace(uname_error, '');
          break;
        case error.includes(mail_error):
          friendlyErrors.mail = error.replace(mail_error, '');
          break;
        case error.includes(pass_error):
          friendlyErrors.pass = error.replace(pass_error, '');
          break;
        case error.includes(nopass_error):
          friendlyErrors.pass = nopass_error;
          break;
        case error.includes(fname_error):
          friendlyErrors.fname = error.replace(fname_error, '');
          break;
        case error.includes(lname_error):
          friendlyErrors.lname = error.replace(lname_error, '');
          break;
        case error.includes(confirmation_error):
          friendlyErrors.confirmation = error.replace(confirmation_error, '');
          break;
      }
    }

    // check if we found any identifiable errors
    // if not, return a general error that
    // we can use to pass info along to the
    // users
    if (Object.keys(friendlyErrors).length) {
      return {
        ...friendlyErrors,
        general: 'Please correct the following errors',
      };
    } else {
      // return a general error
      return {
        general: response,
      };
    }
  };

  return (
    <div className="login-form-section">
      <h3 className="t-center t-mxlarge t-heading">Become a member!</h3>

      <p className="intro">
        As a member of My Loch Gallery you can set up a collector profile, save
        artworks and be notified of new work by your favourite artists.
      </p>

      <form onSubmit={handleFormSubmission}>
        <fieldset>
          <legend>Your Information</legend>
          <div className="cols">
            <div className="input-item col-6">
              <label className="t-small" htmlFor="first_name">
                First Name
              </label>
              <input
                value={firstName}
                autoComplete="first_name"
                id="first_name"
                name="first_name"
                onChange={(e) => setFirstNameField(e.target.value)}
                type="text"
                placeholder="First Name"
                data-error={error.fname && true}
              />
              {error.fname && <span className="error">{error.fname}</span>}
            </div>
            <div className="input-item col-6">
              <label className="t-small" htmlFor="last_name">
                Last Name
              </label>
              <input
                value={lastName}
                autoComplete="last_name"
                id="last_name"
                name="last_name"
                onChange={(e) => setLastNameField(e.target.value)}
                type="text"
                placeholder="Last Name"
                data-error={error.lname && true}
              />
              {error.lname && <span className="error">{error.lname}</span>}
            </div>
            <div className="input-item col-12">
              <label className="t-small" htmlFor="email">
                Email
              </label>
              <input
                value={mail}
                autoComplete="email"
                id="email"
                name="email"
                onChange={(e) => setEmailField(e.target.value)}
                type="email"
                placeholder="Your Email"
                data-error={error.mail && true}
              />
              {error.mail && <span className="error">{error.mail}</span>}
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Create a password</legend>
          <p>
            Password must contain at least 8 characters, and include at least
            one lowercase letter, one uppercase letter, and one digit.
          </p>
          <div className="columns">
            <div className="input-item is-6 column">
              <label className="t-small" htmlFor="password">
                Password
              </label>
              <input
                value={pass}
                autoComplete="new-password"
                id="password"
                name="password"
                onChange={(e) => setPasswordField(e.target.value)}
                type="password"
                placeholder="Password"
                data-error={error.pass && true}
              />
              {/* {passwordStrength && (<div className="pwd-strength-indicator" data-strength={passwordStrength}></div>)} */}
              {error.pass && <span className="error">{error.pass}</span>}
            </div>

            <div className="input-item is-6 column">
              <label className="t-small" htmlFor="password">
                Confirm Password
              </label>
              <input
                confirmPass
                value={confirmPass}
                id="password_1"
                name="password_1"
                onChange={(e) => setConfirmPasswordField(e.target.value)}
                type="password"
                placeholder="Confirm Password"
                data-error={error.confirmpass && true}
              />
              {error.confirmpass && (
                <span className="error">{error.confirmpass}</span>
              )}
            </div>
          </div>
        </fieldset>

        <fieldset>
          <label className="checkbox">
            I agree to Loch Gallery's{' '}
            <a href="/terms-and-conditions" target="_blank">
              Terms of Use
            </a>{' '}
            and{' '}
            <a href="/privacy-policy" target="_blank">
              Privacy Policy
            </a>
            , and to receive emails from Loch Gallery.
            <input
              type="checkbox"
              name="agreement"
              onChange={() => setAgreement(!agreement)}
              value={false}
            />
            <span className="checkmark"></span>
          </label>
          {error.agreement && <span className="error">{error.agreement}</span>}
        </fieldset>

        {error.general && <div className="callout error">{error.general}</div>}

        {error.confirmation && (
          <div className="callout error">{error.confirmation}</div>
        )}

        {loading && (
          <div className="callout warning">
            Registering Account, please wait...
            <img src={Loading} />
          </div>
        )}

        <input
          className="button"
          type="submit"
          data-loading={loading && true}
          value={loading ? 'Loading...' : 'BECOME A MEMBER'}
        />
        <div className="form-element center">
          <p>
            Already have an account? <a onClick={onLogin}>Log in here</a>
          </p>
        </div>
      </form>

      {success && <div className="callout success">Success!</div>}
    </div>
  );
}
