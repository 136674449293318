import { navigate } from 'gatsby';
import * as SecureLS from 'secure-ls';
import BackupSecureLS from '../helper/secure-ls'; // this exists to get around a static rendering issue
import { verifyUserContact } from './register';
const localStorage = typeof window !== 'undefined' ? new SecureLS() : BackupSecureLS;
export const baseUrl = `//cms.lochgallery.com`;
export const isLoggedInUrl = `${baseUrl}/user/login_status?_format=json`;

const loginUrl = `${baseUrl}/user/login?_format=json`;
const cookieExpiration = 2000000;

/* This check is to ensure that this code gets executed in browser because
 * If we run this code without this check your gatsby develop will fail as it won't be able
 * to access localStorage on build time
 */
export const isBrowser = typeof window !== 'undefined';

// helper function to convert http to https so we do not encounter
// mixed content errors in browser
export const forceHTTPS = (str) => {
  if (!str) return null;
  try {
    return str.replace("http", "https");
  } catch(e) {
    console.log('Force HTTPS Error:', e.message);
    return;
  }
}

// replace IP address with cms
export const forceCMS = (str) => {
  if (!str) return null;
  try {
    return str.replace("18.219.219.206:8080", "cms.lochgallery.com");
  } catch(e) {
    console.log('Force CMS Error:', e.message);
    return;
  }
}

// Helper function to get the current status of the user
export const isLoggedIn = async () => {
  const csrf = await getWithExpiry('csrf-token');

  if (csrf) {
    const res = await fetch(
      isLoggedInUrl,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf,
        },
        credentials: 'include',
      });
    
    const response = await res.text();
    return response;
  }
  clearLocalStorage();
  return false;
};

/**
 *  Login the user.
 *
 *  Save the token in local storage.
 */
export const handleLogin = async (username, password) => {

  let data = await drupalLogIn(username, password);

  // if non-anoynmous, log out and log back in
  if (data.message && data.message === 'This route can only be accessed by anonymous users.') {
      await drupalLogout();
      data = await drupalLogIn(username, password);
  }

  if (data !== undefined && data && data.current_user) {
    const saved = saveInfoToLocalStorage(data);
    const userUser = data?.current_user?.name || false;
    if (!userUser) {
      return { message: 'Error Name: "Artistry". Could not access your user details.' }
    }
    let userData = await drupalUserInfo(userUser);

    // if no user data, verify user contact node 
    // and then re-retrieve it.
    if (!userData.data) {
      await verifyUserContact(username, password);
      await verifyUserContact(username, password); // intentional.
      userData = await drupalUserInfo(username);
    }
    // console.log('USER DATA', data);

    // get paragraph user info
    if (userData.data) {
      let userDataWithParagraph = userData.data.filter((d) => d?.relationships?.field_address_paragraphs?.data[0]?.id);
          userDataWithParagraph = userDataWithParagraph.sort((a,b) => {
              return Date.parse(a?.attributes?.created) > Date.parse(b?.attributes?.created);
          }).reverse();
                                
      if (!userDataWithParagraph || !userDataWithParagraph.length) {
        return { message: 'Error Name: "Classic". Could not access your user details.' }
      }
      const paragraphID = userDataWithParagraph[0]?.relationships?.field_address_paragraphs?.data[0]?.id;
      if (!paragraphID) {
        return { message: 'Error Name: "Contemporary". Could not access your user details.' }
      }
      const userParagraph = await drupalUserParagraph(paragraphID);

      if (paragraphID) {
        userData.data = [];
        userData.data[0] = {...userDataWithParagraph[0]};
        userData.data[0].relationships.field_address_paragraphs.data = {...userParagraph};
      }

      if (saved && userData && userParagraph) {
        return userData;
      }
    }
    return { message: 'Error Name: "Historical". Could not access your user details.' }
  }
  return data;
};

/**
 * Log the current user out.
 *
 * Deletes the token from local storage.
 */
export const handleLogout = async () => {
  const res = await drupalLogout();
  if (res) {
    clearLocalStorage();
    return;
  }
};

/**
 * Helper function to store token into local storage
 **/
const saveInfoToLocalStorage = (json) => {
  setWithExpiry('csrf-token', json.csrf_token, cookieExpiration);
  //setWithExpiry('auth-token', authToken, cookieExpiration);
  setWithExpiry('logout-token', json.logout_token, cookieExpiration);
  setWithExpiry('uid', json.current_user.uid, cookieExpiration);
  setWithExpiry('name', json.current_user.name, cookieExpiration);
  //setWithExpiry('mail', json.current_user.mail, cookieExpiration);

  function setWithExpiry(key, value, ttl) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.set(key, JSON.stringify(item));
  }

  return true;
};

/**
 * Login request to Drupal.
 *
 * Exchange username and password.
 * @param username
 * @param password
 * @returns {Promise<void>}
 *   Returns a promise that resolves to JSON response from Drupal.
 */
const drupalLogIn = async (username, password) => {

  const response = await fetch(loginUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      name: username,
      pass: password,
    }),
  });
  const json = await response.json();
  return json;
};

/**
 * Logs the user out on Drupal end.
 */
const drupalLogout = async () => {
  const logoutoken = await getWithExpiry('logout-token');
  
  try {
    const res = await fetch(
      `${baseUrl}/user/logout?_format=json&token=${logoutoken}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    );
    console.log('RESPONSE', res.json());
  } catch(e) {
    console.log('ERROR', e);
  }
  return;
};

/**
 * Get user info once logged in
 * @param String uid
 */
export const drupalUserInfo = async (name) => {
  const csrf = await getWithExpiry('csrf-token');

  try {
    // now we're going to retrieve the user from the username, 
    // in order to grab the email from the return result
    // !important: this is for scenarios where username and email don't match
    // there are a few of these from the old system
    const userNode = await drupalUser(name);
    const userMail = userNode.data[0].attributes.mail;
    // then we'll get the contact node from the user's email
    const res = await fetch(
      `${baseUrl}/jsonapi/node/contact?filter[field_email_address][value]=${userMail}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "Basic " + btoa(username + ":" + password),
          'X-CSRF-Token': csrf,
        },
        credentials: 'include',
      }
    );

    if (res.ok) {
      let json = await res.json();
      if (json.data.length) {
        // contact node exists
        return json;
      } else {
        return false;     
      }
      
    }
    
    return res;
  
  } catch (error) {
    console.log(error);
  }
};

/**
 * Get user info once logged in
 * @param String uid
 */
 export const drupalUser = async (name) => {
  const csrf = await getWithExpiry('csrf-token');

  try {
    // then we'll get the contact node from the user's email
    const res = await fetch(
      `${baseUrl}/jsonapi/user/user?filter[name]=${name}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "Basic " + btoa(username + ":" + password),
          'X-CSRF-Token': csrf,
        },
        credentials: 'include',
      }
    );

    if (res.ok) {
      let json = await res.json();
      if (json.data.length) {
        // contact node exists
        return json;
      } else {
        return false;     
      }
      
    }
    
    return res;
  
  } catch (error) {
    console.log(error);
  }
};

/**
 * Get user paragraph info once logged in
 * @param String uid
 */
 export const drupalUserParagraph = async (uid) => {
  const csrf = await getWithExpiry('csrf-token');

  try {
    const res = await fetch(
      `${baseUrl}/jsonapi/paragraph/address/${uid}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf,
        },
        credentials: 'include',
      }
    );

    if (res.ok) {
      let json = await res.json();
      if (json.data) {
        // contact node exists
        return json.data;
      } else {
        return false;     
      }
      
    }
    
    return res;
  
  } catch (error) {
    console.log(error);
  }
};

/**
 * Get User Node
 * @param String uid
 * @param String username
 * @param String password
 */
 export const drupalGetUserNode = async (uid, username, password) => {

  const csrf = getWithExpiry('csrf-token');
  try {
    const res = await fetch(
      `${baseUrl}/jsonapi/user/user?filter[uid][value]=${uid}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "Basic " + btoa(username + ":" + password)
          'X-CSRF-Token': csrf,
        },
        credentials: 'include',
      }
    );

    if (res.ok) {
      return await res.json();
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * Delete the drupal user account
 * @param String uid
 */
 export const drupalDeleteUser = async (password) => {
  const uid = await getWithExpiry('uid');
  const username = await getWithExpiry('name');
  const csrf = await getWithExpiry('csrf-token');

  // attempt to log in to confirm the password
  /* const loginRes = await drupalLogIn(username, password);
  if (loginRes.message) {
      // there was an error and it failed
      if (loginRes.message.includes('Sorry, unrecognized username')) {
         return { error: true, message: 'Password incorrect'};
      }
  }
 */
  // if login is successful, use login credentials to delete the account
  try {
    const res = await fetch(
      `${baseUrl}/user/${uid}?_format=json`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf,
        },
        credentials: 'include',
      }
    );

    if (res.ok) {
      return true;
    } else {
      console.log('User Account Delete > Request Error', res);
      return {error: true, message: 'There was an error and we were unable to remove your account'}
    }
  } catch (error) {
    console.log('User Account Delete > General Error', error);
    return {error: true, message: 'There was an error and we were unable to remove your account'}
  }
};


/**
 * Helper function to clear local storarge and clear cookies
 */
export const clearLocalStorage = () => {
  localStorage.removeAll();
  return true;
};

/**
 * Helper function that will retrieve the item with expiry.
 * @param {String} key
 * @returns String
 */
export const getWithExpiry = async (key) => {
  const itemStr = await localStorage.get(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.remove(key);
    return null;
  }
  return item.value;
};
