import React, { useState } from 'react';
import Modal from './Modal';
import Register from './Register';
import PasswordReset from './PasswordReset';
import Login from './Login';
import BecomeAMember from './BecomeAMember';

const LoginFlow = ({ children, ...props }) => {
  const [loginModalActive, setLoginModalActive] = useState(false);
  const [registerModalActive, setRegisterModalActive] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [passResetModalActive, setPassResetModalActive] = useState(false);

  const toggleLoginModal = (e) => {
    const success = e && e.success ? e.success : false;

    setLoginModalActive(!loginModalActive);
    setRegisterModalActive(false);
    setPassResetModalActive(false);
    setRegisterSuccess(success);
  };

  const closeModal = () => {
    setLoginModalActive(false);
  };

  const toggleRegisterModal = (e) => {
    setRegisterModalActive(!registerModalActive);
    setLoginModalActive(false);
  };

  const togglePassResetModal = (e) => {
    setPassResetModalActive(!passResetModalActive);
    setLoginModalActive(false);
  };

  // if we're server side, we dont need to
  // render this. it was causing issues with 
  // ssr display. 
  if (typeof window === "undefined") {
    return null;
  }
  
  return (
    <div>
      <button {...props} onClick={toggleLoginModal}>
        {children}
      </button>
      <Modal active={loginModalActive} onClose={toggleLoginModal}>
        <Login
          onRegister={toggleRegisterModal}
          registerSuccess={registerSuccess}
          onResetUserPassword={togglePassResetModal}
          setLoginModalActive={closeModal}
        />
      </Modal>

      <Modal active={passResetModalActive} onClose={togglePassResetModal}>
        <PasswordReset onLogin={toggleLoginModal} />
      </Modal>
      <Modal active={registerModalActive} onClose={toggleRegisterModal} className="become-a-member-modal">
        {/*
        <Register
          onLogin={(params) => {
            toggleLoginModal(params);
          }}
        />
        */}
          <BecomeAMember
            className="button"
          >
            Become a member
          </BecomeAMember>
      </Modal>
    </div>
  );
};

export default LoginFlow;
