import React, { useState, useEffect, useContext } from 'react';
import { navigate, Link } from 'gatsby';
import { UserContext } from '../../context/user/context';
import { handleLogin } from '../../util/auth/drupal';
import { Formik } from 'formik';
import { ModalContext } from '../../context/modal/context';

const Login = ({ onRegister, onResetUserPassword, registerSuccess }) => {
  const [message, setMessage] = useState('');
  const [registerMessage, setRegisterMessage]  = useState(false);
  const [loading, setLoading] = useState(false);
  const { userLogin } = useContext(UserContext);

  const {
    modalProps,
    setModalProps
  } = useContext(ModalContext);

  useEffect(() => {
    setRegisterMessage(registerSuccess);
  }, []);

  const logUserIn = async ({ username, password }) => {
    console.log('LOGGING USER IN')
    if (!username || !password) {
      return setMessage(
        'Please fill out your username and password to sign in.'
      );
    }
    try {
      setLoading(true);
      setMessage('');
      setRegisterMessage(false);
      const res = await handleLogin(username, password);

      if (res && res.data) {
        userLogin(res);
        if (registerSuccess) {
         navigate('/user');
        } else {
          setTimeout(()=> {
            window.location.reload();
          }, 300)
        }
        /*
        try {
          setModalProps({active: false})
        } catch(e) {
          console.log('METHOD DOESNT EXIST')
        }
        */
      }

      if (res.message) {
        setMessage(res.message);
      }
      setLoading(false);
    } catch (err) {
      setMessage(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="login-form-section">

      <h3 className="t-center t-mxlarge t-heading">Member Account</h3>
      {message ? <p className="response-message">{message}</p> : null}
      {registerMessage && <div className="callout success">You have successfully registered your user, please log in.</div>}
      <div className="login">
        <Formik
          initialValues={{ username: '', password: '' }}
          onSubmit={async (values, { setSubmitting }) => {
            await logUserIn(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              className={loading ? 'is-loading' : ''}
            >
              <div className="input-item">
                <label className="t-small" htmlFor="username">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Username"
                  value={values.username}
                />
              </div>
              <div className="input-item">
                <label className="t-small" htmlFor="password">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Password"
                  value={values.password}
                />
              </div>

              <input
                type="submit"
                disabled={isSubmitting || errors.email}
                value={loading ? 'Loading' : 'Sign In'}
              />
            </form>
          )}
        </Formik>
        <button
          className="button btn-center btn-space-large btn-outline"
          onClick={onRegister}
        >
          Create New Account
        </button>
        <span
          className="text-button t-underline"
          onClick={onResetUserPassword}
        >
          Forgot password?
        </span>
        <p className="captcha t-center">
          This site is protected by reCAPTCHA and the Google Privacy Policy
          Terms of Service apply.
        </p>
      </div>
    </div>
  );
};

export default Login;
